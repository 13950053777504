<template>
    <div class="formTable scrolling">
        <div class="title flex flex-ai-c flex-jc-c font-w-b">
            {{ myData.name }}
        </div>
        <table class="mytb">
            <!-- <tr class="thtt">
        <th class="title" colspan="4">{{ myData.name }}</th>
      </tr> -->
            <tr class="thtt">
                <th colspan="1">发文字号</th>
                <td colspan="3" class="text-a-l box-bb">{{ myData.documentNumber }}</td>
            </tr>
            <tr class="thtt">
                <th colspan="1">紧急程度</th>
                <td colspan="1" class="text-a-l box-bb">{{ formatJjcd(myData.jjcd) }}</td>
                <th colspan="1">密级程度</th>
                <td colspan="1" class="text-a-l box-bb">{{ Filter(myData.miji) }}</td>
            </tr>

            <tr class="thtt">
                <th colspan="1">拟稿单位</th>
                <td colspan="3" class="text-a-l box-bb">
                    <template v-if="myData.receiverCompany">
                        {{ myData.receiverCompany }}
                    </template>
                    <template v-if="myData.draftCompany">
                        <div v-for="(o, i) in myData.draftCompany" :key="'draftCompany' + i" class="my-item">
                            <a-tag class="tags flex0" color="blue" v-if="o.corpName">{{ o.corpName }}</a-tag>
                            <a-tag class="tags flex0" color="blue" v-else>
                                <OpenData :type="o.type" :openid="o.departmentId" :corpid="o.corpId"></OpenData>
                            </a-tag>
                        </div>
                    </template>
                </td>
            </tr>
            <tr class="thtt">
                <th colspan="1">拟稿人</th>
                <td colspan="1" class="text-a-l box-bb">
                    <div v-for="(o, i) in myData.draftId" :key="'jbr_list' + i" class="my-item">
                        <a-tag class="tags flex0" color="blue">
                            <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
                        </a-tag>
                    </div>
                </td>
                <th colspan="1">联系电话</th>
                <td colspan="1" class="text-a-l box-bb">{{ myData.tellPhone }}</td>
            </tr>
            <tr class="thtt">
                <th colspan="1">文件标题</th>
                <td colspan="3" class="text-a-l box-bb">{{ myData.title }}</td>
            </tr>

            <tr class="thtt">
                <th colspan="1">正文</th>
                <td colspan="3" class="text-a-l box-bb">
                    <div v-if="myData.mainFile" class="upload_file flex flex-ai-c flex-jc-fs">
                        {{ myData.mainFile.fileName }}
                        <fileDownAndPreView :file="myData.mainFile" />
                    </div>
                    <div v-else>
                        <showEditor :content="myData.content" :editorId="'mobileBillTableEditor'"></showEditor>
                    </div>
                </td>
            </tr>
            <tr class="thtt">
                <th colspan="1">附件</th>
                <td colspan="3" class="text-a-l">
                    <div class="file_list">
                        <div class="upload_file flex flex-ai-c flex-jc-fs" v-for="(o, i) in myData.fileIds" :key="i">
                            {{ o.fileName }}
                            <fileDownAndPreView :file="o" />
                        </div>
                    </div>
                </td>
            </tr>

            <tr class="bigtt align_l">
                <th>拟办意见</th>
                <td colspan="3">{{ myData.nbyj }}</td>
            </tr>

            <nodes :list="formatList(list)" />
        </table>
    </div>
</template>
    <script>
import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import fileDownAndPreView from '@/views/fileStream/documentSend/components/fileDownAndPreView.vue'
import showEditor from '@/views/fileStream/documentSend/components/showEditor.vue'
import moment from 'moment'
import mixin from '../minxin'
export default {
    mixins: [mixin],
    props: {
        myData: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    components: {
        OpenData,
        nodes,
        fileDownAndPreView,
        showEditor,
    },
    data() {
        return {
            dom: [],
        }
    },
    computed: {
        list() {
            let listCopy = []
            if (this.myData.nodes && this.myData.nodes.length > 0) {
                listCopy = this.myData.nodes.slice()
                listCopy = listCopy.filter((node) => node.sort !== -1)
            }
            // let lC = this.myData.documentNodeList.slice()
            // lC = lC.filter(node => node.sort !== -1)
            return listCopy
        },
    },
    methods: {
        Filter(s) {
            // eslint-disable-next-line eqeqeq
            const values = this.flow_secrecy_degree.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        formatApprovalTime(approvalTime) {
            if (!approvalTime) return ''
            approvalTime = approvalTime.replace(/-/g, '/')
            const date = new Date(approvalTime)
            const dateStr = date.toLocaleString()
            return dateStr.replace(/\//g, '-')
        },
        formatListMess(node) {
            const arr = []
            if (node.deptInfos && node.deptInfos.length > 0) {
                node.deptInfos.forEach((dep) => {
                    dep.deptUsers.forEach((k) => {
                        arr.push(k)
                    })
                })
            }
            node.nodes = [...node.nodes, ...arr].filter((j) => j.userId && j.approvalFlag !== 2)
        },
        formatList(list) {
            const listCopy = JSON.parse(JSON.stringify(list))
            listCopy.forEach((j) => {
                this.formatListMess(j)
            })
            return listCopy.filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
        },
        formatJjcd(jjcd) {
            return this.flow_urgency_degree.find((j) => j.code === jjcd)?.value
        },
        formatTdtt(swsj) {
            if (!swsj) return ''
            return moment(swsj).format('YYYY-MM-DD')
        },
        formatXzfn(xzfn) {
            return this.flow_file_type.find((j) => j.code === xzfn)?.value
        },
    },
}
</script>
    <style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}
.formTable {
    .font {
        font-size: 16px;
    }
    .resultList {
        padding: 0 !important;
    }

    .mytb {
        width: 100%;
        border: 1px solid black;
        font-size: 14px;
        table-layout: fixed;
        th,
        td {
            text-align: center;
            border: 1px solid black;
            word-break: break-all;
        }
        .thtt {
            height: 50px;
            td {
                padding: 5px 15px;
            }
        }
        .tdtt {
            height: 50px;
        }
        .bigtt {
            height: 150px;
        }
        .align_l {
            td {
                text-align: left;
                padding: 5px 15px;
                vertical-align: text-top;
                white-space: pre-line;
            }
        }
    }
    .title {
        color: red;
        font-size: 20px;
        height: 60px;
    }
}
</style>
    